import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

const NotFoundScreen: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 | Uninow</title>
        <link rel="canonical" href="https://accounts.uninow.de/404" />
      </Helmet>
      <Layout>
        <h1>Not Found 404</h1>
      </Layout>
    </>
  );
};

export default NotFoundScreen;
