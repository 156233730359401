import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import useRest, { HTTPMethod } from '../../hooks/useRest';
import { FormElement, SegmentCard } from '@uninow/web-ui-lib/components/molecules';
import { ButtonType, SecondaryButton, Toggle } from '@uninow/web-ui-lib/components/atoms';

const NotificationPreferenceForm: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { getTokenSilently, user, loading: initLoading } = useAuth();
  const [sendEmailUpdates, setSendEmailUpdates] = useState(true);
  const init = () => {
    setSendEmailUpdates(user.sendEmailUpdates);
  };
  useEffect(() => {
    init();
  }, [initLoading]);
  const { handleSubmit, loading } = useRest({
    // @ts-ignore
    path: `${window.REACT_APP_ACCOUNTS_API_URL}/api/v1/profile/notification-preferences`,
    method: HTTPMethod.PATCH,
    headers: {
      Authorization: `Bearer ${getTokenSilently()}`,
    },
    data: {
      sendEmailUpdates,
    },
    onSuccess: () => {
      console.log('Success');
    },
  });
  return (
    <div className="mt-6 sm:mt-5">
      <form
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <SegmentCard
          title={t('forms.notificationPreferences.heading')}
          description={t('forms.notificationPreferences.info')}
        >
          <FormElement id={'sendEmailUpdates'} label={t('inputs.sendEmailUpdates')}>
            <Toggle
              onClick={() => {
                setSendEmailUpdates(!sendEmailUpdates);
              }}
              active={sendEmailUpdates}
              key={'sendEmailUpdates'}
            />
          </FormElement>

          <div className="pt-6 flex flex-row justify-end">
            <SecondaryButton type={ButtonType.submit} label={t('actions.save')} loading={loading} />
          </div>
        </SegmentCard>
      </form>
    </div>
  );
};

export default NotificationPreferenceForm;
