import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import useRest, { HTTPMethod } from '../../hooks/useRest';
import Input, { InputType } from '../Input';
import { SegmentCard } from '@uninow/web-ui-lib/components/molecules';
import { SecondaryButton, ButtonType } from '@uninow/web-ui-lib/components/atoms';

const ChangeEmailForm: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { getTokenSilently } = useAuth();
  const [email, setEmail] = useState('');
  // @ts-ignore
  const { handleSubmit, loading, validationMessages } = useRest({
    // @ts-ignore
    path: `${window.REACT_APP_ACCOUNTS_API_URL}/api/v1/email-change`,
    method: HTTPMethod.POST,
    headers: {
      Authorization: `Bearer ${getTokenSilently()}`,
    },
    data: {
      email,
    },
    onSuccess: () => {
      console.log('Success');
    },
  });
  return (
    <div className="mt-6 sm:mt-5">
      <form
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <SegmentCard title={t('forms.changeEmail.heading')} description={t('forms.changeEmail.info')}>
          <Input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            type={InputType.password}
            label={t('inputs.newEmail')}
            id="email"
            required={true}
            value={email}
            validationMessages={validationMessages}
          />
          <div className="pt-6 flex flex-row justify-end">
            <SecondaryButton type={ButtonType.submit} label={t('actions.save')} loading={loading} />
          </div>
        </SegmentCard>
      </form>
    </div>
  );
};

export default ChangeEmailForm;
