import React from 'react';
import { UniNowLogo } from '@uninow/web-ui-lib/components/atoms';
import { SecondaryLayout } from '@uninow/web-ui-lib/components/templates';
import { useAuth } from '../providers/AuthProvider';
import history from '../utils/history';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Layout: React.FC = ({ children }) => {
  // @ts-ignore
  const { user, isAuthenticated, logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    history.push('/');
  };

  return (
    <SecondaryLayout
      logoutTitle={'Logout'}
      onLogout={handleLogout}
      isAuthenticated={isAuthenticated}
      logo={
        <Link to={isAuthenticated ? '/profile' : `/`}>
          <UniNowLogo className="h-10 " />
        </Link>
      }
      userTitle={user ? `${user.firstName} ${user.lastName}` : undefined}
      footer={<Footer />}
    >
      {children}
    </SecondaryLayout>
  );
};

export default Layout;
