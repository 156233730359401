export default `
# **Nutzungsbedingungen für die Nutzung, Registrierung und Teilnahme der Web-App "UniNow Account"**

*Version 1.1 / Stand Januar 2021*

### **1. Anbieter und Geltungsbereich der Nutzungsbedingungen** 

1. Die Web-App UniNow Account (im Folgenden "App") wird betrieben von der Firma UniNow GmbH, vertreten durch die Geschäftsführer Tobias Steenweg und Stefan Wegener, Dorotheenstraße 10, D-39104 Magdeburg (im Folgenden: „Anbieter“). Die App ermöglicht die Verwaltung des UniNow Accounts und den damit verbundenen Geräten.
2. Diese Nutzungsbedingungen gelten gegenüber allen Nutzern der App (im Folgenden: Nutzer). Sie regeln das Nutzungsverhältnis zwischen Anbieter und Nutzer. 
3. Mit Beginn der Nutzung, spätestens mit Registrierung stimmt der Nutzer der ausschließlichen Geltung dieser Nutzungsbedingungen zu. 
4. Es gilt jeweils die zum Zeitpunkt der Nutzung bzw. Registrierung aktuelle Version der Nutzungsbedingungen, die auch auf der App bzw. auf der Webseite des Anbieters unter www.uninow.de zur Verfügung steht.

### **2. Vertragsgegenstand, Leistungen, Erreichbarkeit**

1. Der Anbieter stellt dem Nutzer – gegebenenfalls nach Durchführung der Registrierung – die Möglichkeit zur Verfügung, dessen UniNow Account sowie die dazugehörigen Geräte zu verwalten. Der UniNow Account ermöglicht den Zugriff auf weitere Services der UniNow GmbH, welche ebenfalls über diese App verwaltet werden können.
2. Die Registrierung und Nutzung der App ist grundsätzlich kostenlos. Dem Anbieter behält es sich jedoch vor, die App in Zukunft durch kostenpflichtige zusätzliche Funktionalitäten (In-App-Käufe) zu erweitern. Die Inanspruchnahme solcher kostenpflichtiger Funktionalitäten ist für den Nutzer aber stets freiwillig.  
3. Der Anbieter behält sich vor, auch ohne Ankündigung und jederzeit bestimmte Inhalte zu entfernen, zu löschen oder zu sperren, insbesondere dann, wenn Dritte ihn dazu auffordern, bspw. durch Kenntnisgabe rechtswidriger Inhalte.  
4. Die kostenfreien Nutzungsmöglichkeiten können jederzeit unangekündigt angepasst, also entweder erweitert oder eingestellt, werden.  
5. Der Nutzer erwirbt keinen Anspruch darauf, dass bestimmte Funktionalitäten und Nutzungsmöglichkeiten bestehen, bestehen bleiben oder zukünftig eingerichtet werden. 
6. Der Anbieter gewährleistet für die kostenfreie Nutzung der Plattform keine bestimmte Erreichbarkeit. Der Anbieter ist bemüht ein Höchstmaß an Erreichbarkeit im Rahmen seines Einflussbereichs zu ermöglichen. 
7. Der Anbieter kann jederzeit den Zugang zu den Leistungen beschränken, sofern die Sicherheit des Netzbetriebes, die Aufrechterhaltung der Netzintegrität, insbesondere die Vermeidung schwerwiegender Störungen des Netzes, der Software oder gespeicherter Daten dies erfordern.  

### **3. Registrierung, Kennwort**

1. Der Nutzer muss sich gegebenenfalls vorab registrieren, um die App nutzen oder Inhalte ansehen zu können. 
2. Ein Anspruch auf Registrierung bzw. auf Gewährung der Nutzungsmöglichkeit durch den Anbieter besteht nicht. Nutzungsberechtigt sind nur volljährige und voll geschäftsfähige natürliche Personen oder solche die mit Erlaubnis ihres gesetzlichen Vertreters handeln.  
3. Der Nutzer ist aus Gründen der Sicherheit verpflichtet ein Passwort zu wählen, welches mindestens acht Zeichen hat und dabei mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen aufweist.  

### **4. Account**

1. Der Nutzer erhält – gegebenenfalls im Rahmen der Registrierung – einen Account, also eine Zugangsmöglichkeit zur Nutzung der App. Der Account beinhaltet die einfache, jederzeit frei widerrufliche und nicht übertragbare Berechtigung zur Nutzung der App im Rahmen der Erreichbarkeit.  
2. Der Anbieter ist berechtigt, Accounts zu löschen, wenn sich angegebene Daten als unwahr herausstellen sollten oder der berechtigte Verdacht besteht, dass einzelne Angaben unwahr sind.  

### **5. Datenschutz, Ausdrückliche Einwilligung in die Datenverarbeitung**

1. Neben diesen Nutzungsbedingungen ist die gesonderte Datenschutzerklärung und die gesonderte datenschutzrechtliche Einwilligungserklärung des Anbieters zu beachten. Diese ist jederzeit über die App abrufbar. 
2. Mit Beginn der Nutzung der App, spätestens mit Registrierung erklärt der Nutzer sowohl, dass er von dieser gesonderten Datenschutzerklärung Kenntnis erlangt hat, als auch seine ausdrückliche Einwilligung in die Verarbeitung seiner personenbezogenen Daten, die in der gesonderten datenschutzrechtlichen Einwilligungserklärung dargestellt ist. 

### **6. Löschung bzw. Sperrung von Accounts durch den Anbieter**
1. Der Anbieter behält sich den Ausschluss einzelner Nutzer vor. Der Anbieter ist berechtigt, Accounts zu löschen bzw. zu sperren, wenn tatsächliche Anhaltspunkte vorliegen, die den Verdacht begründen, dass

  * vom Nutzer über bzw. mithilfe der App Rechtsverletzungen begangen wurden oder werden;
  * der Nutzer anderweitig vorsätzlich gegen diese Nutzungsbedingungen verstößt.

Diese Aufzählung ist nicht abschließend. Der Anbieter behält sich weitere Gründe für eine Löschung bzw. Sperrung von Accounts vor. 

2. Der Anbieter wird die Belange des Nutzers, insbesondere vor dem Hintergrund der Schwere des Verstoßes bzw. des Verdachts bei seiner Entscheidung angemessen berücksichtigen. 
3. In Zweifelsfragen obliegt die Beweislast dem Nutzer. Dieser hat darzulegen und nachzuweisen, dass er sich entgegen des Verdachts des Anbieters in Übereinstimmung mit dem geltenden Recht bzw. diesen Nutzungsbedingungen verhalten hat bzw. ein Lösch- bzw. Sperrgrund nicht vorliegt. 
4. Ungeachtet der Berechtigung zur Löschung bzw. Sperrung des Accounts bleibt der Anbieter berechtigt, das Vertragsverhältnis mit dem Nutzer insgesamt zu kündigen und/oder sonstige weitere Ansprüche, insbesondere Schadensersatzansprüche geltend zu machen. 

### **7. Schadensersatz, Haftungsfreistellung**

1. Der Nutzer hat dem Anbieter den Schaden zu ersetzen, der diesem aus einer schuldhaften Verletzung der Pflichten des Nutzers entsteht. 
2. Der Nutzer stellt den Anbieter im Falle einer schuldhaften Pflichtverletzung von dessen Haftung gegenüber Dritten frei. Er wird dem Anbieter hierdurch entstandenen Schaden auf Nachweis erstatten. Das gilt dann nicht, wenn der Nutzer die Pflichtverletzung nachweislich nicht zu vertreten hat. 

### **8. Nutzungsdauer**

Die Nutzungsmöglichkeit besteht auf unbestimmte Zeit. 

### **9. Einstellung der Tätigkeit des Anbieters**

1. Der Nutzer hat im Hinblick auf kostenlose Funktionalitäten keinen Anspruch auf Nutzung und Zugangsgewährung. 
2. Der Anbieter jederzeit berechtigt, im Hinblick auf kostenlose Funktionalitäten das Anbieten von Inhalten und die Gewährung des Zugangs zur App zu unterbrechen, auf unbestimmte Zeit oder endgültig zu beenden. 

### **10. Haftung des Anbieters**

1. Der Anbieter (einschließlich Erfüllungsgehilfen und Mitarbeitern des Anbieters sowie mit dem Anbieter verbundenen Unternehmen) haftet nur für solche Schäden, die auf vorsätzlicher oder grob fahrlässiger Handlung beruhen. 
2. Der Anbieter (einschließlich Erfüllungsgehilfen und Mitarbeitern des Anbieters sowie mit dem Anbieter verbundenen Unternehmen) haftet auch für Schäden, die sich aus leicht fahrlässigen Verletzungen wesentlicher Vertragspflichten ergeben, dann jedoch beschränkt auf den vorhersehbaren typischen Durchschnittsschaden. 
3. Der Anbieter (einschließlich Erfüllungsgehilfen und Mitarbeitern des Anbieters sowie mit dem Anbieter verbundenen Unternehmen) haftet immer für Schäden an Leben, Körper und Gesundheit, sowie nach dem Produkthaftungsgesetz. 

### **11. Änderungen der Nutzungsbedingungen**

1. Der Anbieter behält sich das Recht vor, die Nutzungsbedingungen zu ändern. Der Nutzer wird über die Änderungen ausdrücklich in geeigneter Weise informiert und auf die – hervorgehobenen – geänderten Passagen hingewiesen. In der Regel erfolgt der Hinweis durch Veröffentlichung innerhalb der App. Dem Anbieter bleibt jedoch grundsätzlich vorbehalten zu entscheiden, wie der Nutzer auf die Änderungen aufmerksam gemacht wird. 
2. Gibt der Nutzer nicht innerhalb von sechs (6. Wochen nach Information über die Neufassung zu erkennen, dass er die Neufassung nicht akzeptiert, ist dies eine stillschweigende Zustimmung und ab diesem Zeitpunkt gilt die Neufassung. Der Anbieter verpflichtet sich mit der Information über die Änderungen den Nutzer auf diese Bedeutung seines Verhaltens besonders hinzuweisen. 
3. Im Falle eines fristgemäßen Widerspruchs des Nutzers ist der Anbieter berechtigt, die Nutzungsmöglichkeit der App zu dem Zeitpunkt zu beenden, zu dem die neu gefassten Nutzungsbedingungen in Kraft treten sollen. 

### **12. Rechte an der Plattform**

1. Sämtliche Kennzeichenrechte, Rechte an geschäftlichen Bezeichnungen, Namensrechte, Markenrechte, Urheberrechte, Leistungsschutzrechte und sonstige Rechte an der App, ihren einzelnen grafischen und textlichen Elementen und ihren Funktionalitäten und Diensten stehen alleine dem Anbieter zu und dürfen ohne vorherige schriftliche Zustimmung des Anbieters nicht genutzt, verbreitet, kopiert, vervielfältigt, öffentlich zugänglich gemacht, aufgeführt, gesendet oder sonst wie verwertet werden. 
2. Eine Übertragung von Verwertungs- oder sonstigen Rechten auf den Nutzer findet nicht statt. 

### **13. Salvatorische Klausel**

Sollte eine der hier geregelten Bestimmungen unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt. 

### **14. Rechtsordnung, Erfüllungsort, Abtretung, Aufrechnung**

1. Für die Geltung dieser Nutzungsbedingungen findet ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung. Handelt der Nutzer als Verbraucher, so geht unabhängig davon stets das für seinen Wohnsitzstaat geltende zwingende Verbraucherschutzrecht vor. 
2. Erfüllungsort ist der Sitz des Anbieters. 
3. Rechte und Pflichten aus diesen Nutzungsbedingungen können nicht abgetreten oder sonst wie übertragen werden, ohne dass die andere Partei zuvor zugestimmt hat. 
4. Der Nutzer kann die Aufrechnung nur mit solchen Gegenansprüchen erklären, die unstreitig oder rechtskräftig festgestellt sind. 

`;
