import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import useRest, { HTTPMethod } from '../../hooks/useRest';
import DeviceEmptyCollection from './DeviceEmptyCollection';
import DevicePhone, { DeviceContract } from './DeviceIPhone';
import FormHeading from '../FormHeading';

const DevicesCollection: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { getTokenSilently } = useAuth();
  const { handleSubmit, loading, data } = useRest({
    // @ts-ignore
    path: `${window.REACT_APP_ACCOUNTS_API_URL}/api/v1/profile/devices`,
    method: HTTPMethod.GET,
    headers: {
      Authorization: `Bearer ${getTokenSilently()}`,
    },
    data: {},
    onSuccess: () => {
      console.log('Success');
    },
  });

  useEffect(() => {
    handleSubmit();
  }, []);
  return (
    <>
      <div className="">
        <div className="border-b">
          <FormHeading>{t('screens.devices.title')}</FormHeading>
        </div>
        <div className="pt-4">
          {!loading && data.data !== undefined ? (
            <>
              {data.data.length <= 0 ? (
                <DeviceEmptyCollection />
              ) : (
                <div className="grid grid-cols-2 gap-2 pt-4">
                  {data.data.map((device: DeviceContract) => (
                    <DevicePhone userAgent={device.userAgent} createdAt={device.createdAt} />
                  ))}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default DevicesCollection;
