import { useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Errors from '../utils/Errors';

export enum HTTPMethod {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

export type RestHookProps = {
  path: string;
  method: HTTPMethod;
  data: { [key: string]: any };
  headers: { [key: string]: any };
  onSuccess: () => any;
};

export type RestHookReturnType = {
  handleSubmit(): void;
  loading: boolean;
  errors: { [key: string]: any };
  validationMessages: Errors;
  data: undefined | any;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: RestHookProps): RestHookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>(new Errors());
  const [data, setData] = useState([]);
  /*
	Here we build the call from the props and
	 */
  const handleSubmit = async () => {
    setLoading(true);
    await axios({
      method: props.method,
      url: props.path,
      data: props.data,
      headers: props.headers,
    })
      .then((response: AxiosResponse) => {
        setData(response.data);
        props.onSuccess();
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 422) {
            const t = new Errors();
            // @ts-ignore
            t.record(error.response.data.messages);
            setErrors(t);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { handleSubmit, loading, errors, validationMessages: errors, data };
};
