// @ts-ignore
export const queryParam = (location, name: string): string => {
  const result: { [key: string]: string } = {};
  location.search
    .substring(1)
    .split('&')
    .forEach((queryPair: string) => {
      const bar = queryPair.split('=');
      result[bar[0]] = bar[1];
    });
  return result[name];
};
