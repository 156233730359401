import React, { FormEvent, useState } from 'react';
import Errors from '../utils/Errors';
import axios from 'axios';
import history from '../utils/history';
import { useTranslation } from 'react-i18next';
import { ButtonType, Card, PrimaryButton } from '@uninow/web-ui-lib/components/atoms';

export type PasswordResetProps = {
  token: string;
};

const PasswordResetForm: React.FC<PasswordResetProps> = (props: PasswordResetProps) => {
  const [token, setToken] = useState(props.token);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(new Errors());
  const { t } = useTranslation();
  const resetPassword = async (e: FormEvent) => {
    e.preventDefault();
    axios
      // @ts-ignore
      .post(`${window.REACT_APP_ACCOUNTS_API_URL}/api/v1/password-reset`, {
        password,
        passwordConfirmation,
        token,
      })
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          history.push('/');
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            const e = new Errors();
            e.record(error.response.data.messages);
            setErrors(e);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Card>
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-600 mb-4">{t('screens.set-password.title')}</h2>
          {success ? (
            <p className="text-green-800 my-2 font-medium text-sm p-2 bg-green-50 rounded-lg">
              {t('screens.set-password.success')}
            </p>
          ) : null}
          <form
            action="#"
            method="POST"
            onSubmit={async (e) => {
              await resetPassword(e);
            }}
          >
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  id="email"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            {errors.has('password') ? (
              <span className="text-sm mt-1 block text-red-600">{errors.get('password')}</span>
            ) : null}

            <div className="mt-6">
              <label htmlFor="password_confirmation" className="block text-sm font-medium leading-5 text-gray-700">
                Password Confirmation
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  id="password_confirmation"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            {errors.has('passwordConfirmation') ? (
              <span className="text-sm mt-1 block text-red-600">{errors.get('passwordConfirmation')}</span>
            ) : null}

            <div className="mt-6">
              <PrimaryButton label={t('screens.set-password.send')} type={ButtonType.submit} />
            </div>
          </form>
        </div>
      </Card>
    </>
  );
};

export default PasswordResetForm;
