import React from 'react';
import PersonalInformationForm from './PersonalInformationForm';
import ChangePasswordForm from './ChangePasswordForm';
import ChangeEmailForm from './ChangeEmailForm';
import NotificationPreferenceForm from './NotificationPreferenceForm';
import DeleteAccountCard from './DeleteAccountCard';
import { useTranslation } from 'react-i18next';

const ProfileForm: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={'max-w-screen-md mx-auto'}>
      <div className="text-center pt-2 pb-8">
        <h3 className="text-2xl">{t('screens.profile.personalData')}</h3>
        <p className="text-gray-500 pt-2">{t('screens.profile.personalDataSubline')}</p>
      </div>
      <PersonalInformationForm />
      <ChangePasswordForm />
      <ChangeEmailForm />
      <NotificationPreferenceForm />
      <DeleteAccountCard />
    </div>
  );
};

export default ProfileForm;
