import React from 'react';
import { SimpleCard } from '@uninow/web-ui-lib/components/molecules';
import { useTranslation } from 'react-i18next';

export interface DeviceContract {
  userAgent: { [key: string]: any };
  createdAt: string;
}

const DeviceIPhone: React.FC<DeviceContract> = (device: DeviceContract) => {
  const { t, i18n } = useTranslation();
  return (
    <SimpleCard title={t('screens.devices.device')}>
      <div className={'flex flex-col justify-between h-full'}>
        <h5>
          {device.userAgent.system} <small>{device.userAgent.systemVersion}</small>
        </h5>
        <span className={'text-base font-normal text-gray-500'}>
          {t('screens.devices.lastLogin')}: {new Date(device.createdAt).toLocaleDateString(i18n.language)}
        </span>
      </div>
    </SimpleCard>
  );
};

export default DeviceIPhone;
