import React from 'react';
import { Helmet } from 'react-helmet';
import ProfileForm from '../components/profile/ProfileForm';
import { useTranslation } from 'react-i18next';
import EmailVerificationBanner from '../components/EmailVerificationBanner';
import { useLocation, Route, Switch, useRouteMatch, NavLink, Redirect } from 'react-router-dom';
import DevicesCollection from '../components/devices/DevicesCollection';
import ApplicationCollection from '../components/applications/ApplicationCollection';
import DeleteAccountScreen from './DeleteAccountScreen';
import { BasicLayout } from '@uninow/web-ui-lib/components/templates';
import { SidebarNavigationItem } from '@uninow/web-ui-lib/components/molecules';
import { DeviceMobileIcon, PuzzleIcon, UserIcon } from '@uninow/web-ui-lib/components/atoms';
import { SidebarNavigationItemProps } from '@uninow/web-ui-lib/components/molecules/buttons/SidebarNavigationItem';
import { useAuth } from '../providers/AuthProvider';
import { LANGUAGES } from '../i18n';

const ProfileScreen: React.FC = () => {
  const { t, i18n } = useTranslation();
  let { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const navigation = {
    items: [
      { title: t('nav.profile'), href: `${url}/data`, icon: UserIcon, subElements: [] },
      { title: t('nav.devices'), href: `${url}/devices`, icon: DeviceMobileIcon, subElements: [] },
      { title: t('nav.applications'), href: `${url}/applications`, icon: PuzzleIcon, subElements: [] },
    ],
    renderItem: ({ href, ...props }: SidebarNavigationItemProps) => (
      <NavLink to={href ?? ''} key={props.title} exact={true}>
        <SidebarNavigationItem {...props} active={pathname === href} />
      </NavLink>
    ),
  };
  // @ts-ignore
  const { user, logout } = useAuth();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('screens.profile.title')} | Uninow</title>
        <link rel="canonical" href="https://accounts.uninow.de/profile" />
      </Helmet>
      <BasicLayout
        logoutTitle={'Logout'}
        onLogout={() => {
          logout();
        }}
        navigation={navigation}
        userTitle={user ? `${user.firstName}${user.lastName}` : undefined}
        footer={
          <>
            <a target="_blank" rel="noreferrer noopener"  href="https://legal.uninow.com/accounts/terms-of-use-uninow-account/de/latest" className="hover:bg-gray-100">
              {t('footer.terms')}
            </a>
            {' | '}
            <a target="_blank" rel="noreferrer noopener" href="https://legal.uninow.com/accounts/privacy-policy-uninow-account/de/latest/" className="hover:bg-gray-100">
              {t('footer.privacy')}
            </a>
            {' | '}
            <a target="_blank" rel="noreferrer noopener" href="https://legal.uninow.com/web/imprint/de/latest" className="hover:bg-gray-100">
              {t('footer.imprint')}
            </a>
            {' | '}
            {LANGUAGES.filter((lang) => lang !== i18n.language).map((lang) => (
              <button className="hover:bg-gray-100" onClick={() => i18n.changeLanguage(lang)}>
                {lang.toUpperCase()}
              </button>
            ))}
          </>
        }
      >
        <div className="min-h-90vh flex-shrink-0">
          {/* <EmailVerificationBanner /> */}
          <Switch>
            <Redirect exact from="/profile" to="/profile/data" />
            <Route path={`${path}/data`} component={ProfileForm} />
            <Route path={`${path}/devices`} component={DevicesCollection} />
            <Route path={`${path}/applications`} component={ApplicationCollection} />
            <Route path={`${path}/delete-account`} component={DeleteAccountScreen} />
          </Switch>
        </div>
      </BasicLayout>
    </>
  );
};

export default ProfileScreen;
