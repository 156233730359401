import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../i18n';

const LegalLinks: React.FC = () => {
  const { t, i18n } = useTranslation();

  const links = [
    {
      to: 'https://legal.uninow.com/web/imprint/de/latest',
      text: t('footer.imprint'),
    },
    {
      to: 'https://legal.uninow.com/accounts/privacy-policy-uninow-account/de/latest',
      text: t('footer.privacy'),
    },
    {
      to: 'https://legal.uninow.com/accounts/terms-of-use-uninow-account/de/latest',
      text: t('footer.terms'),
    },
  ];

  return (
    <div className="w-full text-center">
      {links.map(({ to, text }) => (
        <a
          key={to}
          target="_blank"
          rel="noreferrer noopener"
          href={to}
          className="p-3 text-sm font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150"
        >
          {text}
        </a>
      ))}

      {LANGUAGES.filter((lang) => lang !== i18n.language).map((lang) => (
        <button
          className="text-gray-600 hover:text-gray-500 text-sm font-medium"
          onClick={() => i18n.changeLanguage(lang)}
        >
          {lang.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default LegalLinks;
