import React, { useEffect } from 'react';
import useRest, { HTTPMethod } from '../../hooks/useRest';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import Application, { ApplicationContract } from './Application';
import FormHeading from '../FormHeading';

const ApplicationCollection: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { getTokenSilently } = useAuth();
  const { handleSubmit, loading, data } = useRest({
    // @ts-ignore
    path: `${window.REACT_APP_ACCOUNTS_API_URL}/api/v1/profile/applications`,
    method: HTTPMethod.GET,
    headers: {
      Authorization: `Bearer ${getTokenSilently()}`,
    },
    data: {},
    onSuccess: () => {
      console.log('Success');
    },
  });
  useEffect(() => {
    handleSubmit();
  }, []);
  return (
    <>
      <div className="border-b">
        <FormHeading>{t('screens.applicationCollection.heading')}</FormHeading>
        <p className="text-gray-700 text-sm">{t('screens.applicationCollection.info')}</p>
      </div>
      {!loading && data.data !== undefined ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 pt-4">
          {data.data.map((application: ApplicationContract) => (
            <Application name={application.name} description={application.description} url={application.url} />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ApplicationCollection;
