import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './utils/history';
import { Helmet } from 'react-helmet';
import LoginScreen from './screens/LoginScreen';
import ProfileScreen from './screens/ProfileScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import VerifyEmailScreen from './screens/VerifyEmailScreen';
import PrivateRoute from './components/PrivateRoute';
import PasswordResetRequestScreen from './screens/PasswordResetRequestScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import LegalScreen from './screens/LegalScreen';

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Accounts | Uninow</title>
        <link rel="canonical" href="https://accounts.uninow.de/" />
      </Helmet>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={LoginScreen} />
          <Route path="/auth/password-reset" exact component={PasswordResetScreen} />
          <Route path="/auth/forgot-password" exact component={PasswordResetRequestScreen} />
          <Route path="/auth/verify-email" component={VerifyEmailScreen} />
          <Route path="/legal/:document" component={LegalScreen} />
          <PrivateRoute path="/profile" component={ProfileScreen} />
          <Route path="*" component={NotFoundScreen} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
