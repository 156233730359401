import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import useRest, { HTTPMethod } from '../../hooks/useRest';
import Input, { InputType } from '../Input';
import { SecondaryButton, ButtonType } from '@uninow/web-ui-lib/components/atoms';
import { SegmentCard } from '@uninow/web-ui-lib/components/molecules';

const PersonalInformationForm: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { getTokenSilently, user, loading: initLoading } = useAuth();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const init = () => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
  };
  useEffect(() => {
    init();
  }, [initLoading]);

  const { handleSubmit, loading, validationMessages } = useRest({
    // @ts-ignore
    path: `${window.REACT_APP_ACCOUNTS_API_URL}/api/v1/profile`,
    method: HTTPMethod.PATCH,
    headers: {
      Authorization: `Bearer ${getTokenSilently()}`,
    },
    data: {
      firstName,
      lastName,
    },
    onSuccess: () => {
      console.log('Success');
    },
  });
  return (
    <form
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <SegmentCard title={t('forms.personalInformation.heading')} description={t('forms.personalInformation.info')}>
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFirstName(e.target.value);
          }}
          type={InputType.text}
          label={t('inputs.firstName')}
          id="firstName"
          required={true}
          value={firstName}
          validationMessages={validationMessages}
        />
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLastName(e.target.value);
          }}
          type={InputType.text}
          label={t('inputs.lastName')}
          id="lastName"
          required={true}
          value={lastName}
          validationMessages={validationMessages}
        />
        <div className="pt-6 ">
          <SecondaryButton type={ButtonType.submit} label={t('actions.save')} loading={loading} />
        </div>
      </SegmentCard>
    </form>
  );
};

export default PersonalInformationForm;
