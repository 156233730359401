import React from 'react';
import './styles/main.css';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './providers/AuthProvider';
import history from './utils/history';
import './i18n';

// @ts-ignore
const config = {
  // @ts-ignore
  domain: window.REACT_APP_ACCOUNTS_API_URL,
  applicationId: 'com.uninow.profile',
  audience: ['sd'],
};

const onRedirectCallback = (appState: { targetUrl: string }) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

// @ts-ignore
ReactDOM.render(
  <AuthProvider
    // @ts-ignore
    domain={config.domain}
    applicationId={config.applicationId}
    audience={config.audience}
    // @ts-ignore
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </AuthProvider>,
  document.getElementById('root'),
);
