import React from 'react';

export interface FormHeadingContract {
  children: any;
}

const FormHeading: React.FC<FormHeadingContract> = ({ children }) => {
  return (
    <>
      <h3 className="text-xl leading-6 font-medium text-gray-800">{children}</h3>
    </>
  );
};

export default FormHeading;
