import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PasswordResetForm from '../components/PasswordResetForm';
import { queryParam } from '../utils/queryParams';

// @ts-ignore
const PasswordResetScreen: React.FC = ({ match, location }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Password reset | Uninow</title>
        <link rel="canonical" href="https://accounts.uninow.de/auth/password-reset" />
      </Helmet>
      <Layout>
        <PasswordResetForm token={queryParam(location, 'token')} />
      </Layout>
    </>
  );
};

export default PasswordResetScreen;
