import React, { FormEvent, useState } from 'react';
import Errors from '../utils/Errors';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ButtonType, Card, PrimaryButton } from '@uninow/web-ui-lib/components/atoms';

const PasswordResetRequestForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(new Errors());
  const { t } = useTranslation();
  const requestResetLink = async (e: FormEvent) => {
    e.preventDefault();
    await axios
      // @ts-ignore
      .post(`${window.REACT_APP_ACCOUNTS_API_URL}/api/v1/password-recovery`, {
        email,
      })
      .then((response) => {
        setLoading(false);
        setEmail('');
        setSuccess(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            const e = new Errors();
            e.record(error.response.data.messages);
            setErrors(e);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Card>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-600 mb-4">{t('screens.reset-password.title')}</h2>
          <p className="text-sm mb-4 text-gray-800">{t('screens.reset-password.description')}</p>
          {success ? (
            <p className="text-green-800 my-2 font-medium text-sm p-2 bg-green-50 rounded-lg">
              {t('screens.reset-password.success')}
            </p>
          ) : null}
          <form
            action="#"
            method="POST"
            onSubmit={async (e) => {
              await requestResetLink(e);
            }}
          >
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  type="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div>
              {errors.has('email') ? (
                <span className="text-sm mt-1 block text-red-600">{errors.get('email')}</span>
              ) : null}
            </div>
            <div className="mt-6">
              <PrimaryButton label={t('screens.reset-password.send')} type={ButtonType.submit} />
            </div>
          </form>
        </div>
      </Card>
    </>
  );
};

export default PasswordResetRequestForm;
