import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CheckmarkIcon } from '@uninow/web-ui-lib/components/atoms';
import Layout from '../components/Layout';
import { useTranslation } from 'react-i18next';

const VerifyEmailScreen: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Email verified | Uninow</title>
        <link rel="canonical" href="https://accounts.uninow.com/email/verified" />
      </Helmet>
      <Layout>
        <Card>
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <CheckmarkIcon className="h-6 w-6 text-green-600" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{t('screens.verify.title')}</h3>
              <div className="mt-2">
                <p className="text-sm leading-6 text-gray-500 whitespace-pre">{t('screens.verify.description')}</p>
              </div>
            </div>
          </div>
        </Card>
      </Layout>
    </>
  );
};

export default VerifyEmailScreen;
