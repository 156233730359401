import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

import imprint from '../static/imprint';
import privacy from '../static/privacy';
import terms from '../static/terms';
import { Card, Spinner } from '@uninow/web-ui-lib/components/atoms';
import { useTranslation } from 'react-i18next';

const Markdown = lazy(() => import('../components/Markdown'));

// @ts-ignore
const NotFoundScreen: React.FC = ({ match }) => {
  const { document } = match.params;
  const { t } = useTranslation();

  let text;
  switch (document) {
    case 'imprint':
      text = imprint;
      break;
    case 'privacy':
      text = privacy;
      break;
    case 'terms':
      text = terms;
      break;
    default:
      text = '';
      break;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('screens.legal.title')}</title>
        <link rel="canonical" href="https://accounts.uninow.de/legal" />
      </Helmet>
      <Layout>
        <Suspense fallback={<Spinner className="h-6 w-6" />}>
          <Card>
            <Markdown text={text} />
          </Card>
        </Suspense>
      </Layout>
    </>
  );
};

export default NotFoundScreen;
