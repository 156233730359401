import React from 'react';
import { useTranslation } from 'react-i18next';

const DeviceEmptyCollection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="border-blue-300 rounded-lg bg-blue-50 px-4 py-2">
        <h5>{t('screens.devices.empty')}</h5>
      </div>
    </>
  );
};

export default DeviceEmptyCollection;
