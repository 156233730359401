import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PasswordResetRequestForm from '../components/PasswordResetRequestForm';

const PasswordResetRequestScreen: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Password reset | Uninow</title>
        <link rel="canonical" href="https://accounts.uninow.de/auth/password-reset" />
      </Helmet>
      <Layout>
        <PasswordResetRequestForm />
      </Layout>
    </>
  );
};

export default PasswordResetRequestScreen;
