import React, { ChangeEventHandler } from 'react';
import Errors from '../utils/Errors';
import { FormElement } from '@uninow/web-ui-lib/components/molecules';
import { Input as UiInput } from '@uninow/web-ui-lib/components/atoms';

export enum InputType {
  password = 'password',
  email = 'email',
  text = 'text',
}

export interface FormInputContract {
  onChange: ChangeEventHandler;
  type: InputType;
  label: string;
  id: string;
  required: boolean;
  value: string;
  validationMessages?: Errors;
}

const Input: React.FC<FormInputContract> = ({
  onChange,
  type,
  label,
  id,
  required,
  value,
  validationMessages,
}: FormInputContract) => {
  return (
    <FormElement id={id} label={label} validationMessage={validationMessages?.has(id) && validationMessages.get(id)}>
      <UiInput onChange={onChange} ariaLabel={label} type={type} id={id} required={required} value={value} />
    </FormElement>
  );
};

export default Input;
