import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { SegmentCard } from '@uninow/web-ui-lib/components/molecules';

const DeleteAccountCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-6 sm:mt-5">
      <SegmentCard description={t('forms.deleteAccount.info')} title={t('forms.deleteAccount.heading')}>
        <NavLink
          className={classNames('block mb-1 px-3 py-2 text-gray-700 font-medium rounded-r-full')}
          to={`/profile/delete-account`}
        >
          {t('nav.forwardAccountDeletion')}
        </NavLink>
      </SegmentCard>
    </div>
  );
};

export default DeleteAccountCard;
