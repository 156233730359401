import React, { useState, useEffect, useContext, ReactElement } from 'react';
import AuthClient from '@uninow/auth-spa-js/dist/AuthClient';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const AuthContext = React.createContext({});
export const useAuth = () => useContext(AuthContext);

export type AuthProviderProps = {
  children: ReactElement;
  domain: string;
  applicationId: string;
  onRedirectCallback(): void;
  audience: string[];
};

export interface UserContract {
  emailVerified: boolean;
  firstName: string;
  lastName: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  domain,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  applicationId,
  audience,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<UserContract>({ emailVerified: false, firstName: '', lastName: '' });
  const [authClient, setAuth] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      const authFromHook = new AuthClient({
        application: applicationId,
        domain: domain,
      });
      // @ts-ignore
      setAuth(authFromHook);

      if (window.location.search.includes('code=')) {
        //const {appState} = await authFromHook.handleRedirectCallback();
        //onRedirectCallback(appState);
      }
      let isAuthenticated = false;
      try {
        isAuthenticated = await authFromHook.isAuthenticated();
      } catch (e) {
        console.error(e);
      }
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await authFromHook.getUser();
        // @ts-ignore
        setUser(user.data as UserContract);
      }

      setLoading(false);
    };
    initAuth();
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    //await authClient.handleRedirectCallback();
    // @ts-ignore
    const user = await authClient.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const login = async (props: { loginId: string; password: string }) => {
    setLoading(true);
    // @ts-ignore
    const response = await authClient
      .loginWithCredentials({
        loginId: props.loginId,
        password: props.password,
      })
      .catch((e: any) => {
        setLoading(false);
        setIsAuthenticated(false);
        throw e;
      });
    setLoading(false);
    setIsAuthenticated(true);
    return response;
  };

  const logoutUSer = async () => {
    setLoading(true);
    // @ts-ignore
    await authClient.logout();
    setLoading(false);
    setIsAuthenticated(false);
  };

  const fetchUser = async () => {
    // @ts-ignore
    const user = await authClient.getUser();
    // @ts-ignore
    setUser(user.data as UserContract);
  };

  // @ts-ignore
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        fetchUser,
        logout: logoutUSer,
        handleRedirectCallback,
        loginWithCredentials: login,
        // @ts-ignore
        //loginWithRedirect: (...p) => authClient.loginWithRedirect(...p),
        // @ts-ignore
        getTokenSilently: (...p) => authClient.getTokenSilently(...p),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
