import React from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentCard } from '@uninow/web-ui-lib/components/molecules';
import { ExternalLinkIcon } from '@uninow/web-ui-lib/components/atoms';

export interface ApplicationContract {
  name: string;
  description: string;
  url: string;
}

const Application: React.FC<ApplicationContract> = (application: ApplicationContract) => {
  const { t } = useTranslation();
  return (
    <>
      <a
        className="hover:shadow-md transition ease-in-out duration-100 rounded group "
        href={application.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SegmentCard
          title={application.name}
          description={t(application.description, t('apps.default.description'))}
          className={' h-full'}
        >
          <div className={'flex flex-row text-gray-400 group-hover:text-highlight-500 items-center'}>
            <span className={'m-1 flex-shrink overflow-hidden overflow-ellipsis whitespace-nowrap'}>
              {application.url}
            </span>
            <ExternalLinkIcon className={'m-1 w-5'} />
          </div>
        </SegmentCard>
      </a>
    </>
  );
};

export default Application;
