import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import history from '../utils/history';

// @ts-ignore
const PrivateRoute = ({ component: Component, path, ...rest }) => {
  // @ts-ignore
  const { loading, isAuthenticated } = useAuth();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      //await loginWithRedirect({
      //	appState: { targetUrl: path }
      //});
      history.push('/');
    };
    fn();
  }, [loading, isAuthenticated, path]);

  const render = (props: JSX.IntrinsicAttributes) => (isAuthenticated === true ? <Component {...props} /> : null);

  // @ts-ignore
  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
