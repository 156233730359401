import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import history from '../utils/history';
import LegalLinks from './LegalLinks';
import { Input, PrimaryButton, UniNowLogoSmall, ButtonType } from '@uninow/web-ui-lib/components/atoms';
import { useTranslation, Trans } from 'react-i18next';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [authFailed, setAuthFailed] = useState(false);
  // @ts-ignore
  const { loginWithCredentials, fetchUser } = useAuth();
  const { t } = useTranslation();

  const requestLogin = async (e: FormEvent) => {
    e.preventDefault();
    setAuthFailed(false);
    setLoading(true);
    loginWithCredentials({
      loginId: email,
      password,
    }) // @ts-ignore
      .then(async (response) => {
        setAuthFailed(false);
        setEmail('');
        setPassword('');
        await fetchUser();
        history.push('/profile');
      }) // @ts-ignore
      .catch((e) => {
        setLoading(false);
        setAuthFailed(true);
      });
  };

  return (
    <>
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm ">
            <div>
              <UniNowLogoSmall className="w-16 h-16 fill-current text-highlight-500" />
              <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-700">
                <Trans
                  i18nKey="forms.login.prompt" // optional -> fallbacks to defaults if not provided
                  defaults="Sign in to your <bold>UniNow</bold> account" // optional defaultValue
                  components={{ bold: <span className="text-highlight-500" /> }}
                />
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form
                  action="#"
                  method="POST"
                  onSubmit={async (e) => {
                    await requestLogin(e);
                  }}
                >
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                      {t('forms.login.email')}
                    </label>
                    <Input
                      ariaLabel={'email'}
                      required
                      type={'email'}
                      id={'email'}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mt-6">
                    <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                      {t('forms.login.password')}
                    </label>
                    <Input
                      ariaLabel={'password'}
                      required
                      type={'password'}
                      id={'password'}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="mt-6 flex items-center justify-between">
                    <div className="text-sm leading-5">
                      <Link
                        to={'/auth/forgot-password'}
                        className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      >
                        {t('forms.login.forgot')}
                      </Link>
                    </div>
                  </div>

                  <div className="my-6">
                    {authFailed ? (
                      <span className="h-12 block">
                        <div className="text-highlight-500 text-md font-semibold text-center pt-3 transition-all duration-300">
                          {t('forms.login.failed')}
                        </div>
                      </span>
                    ) : null}
                    <PrimaryButton
                      label={t('forms.login.signIn')}
                      ariaLabel={'Login'}
                      type={ButtonType.submit}
                      disabled={loading}
                      loading={loading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <LegalLinks />
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover object-left-top"
            src="/images/background.jpg"
            alt="marketing image"
          />
        </div>
      </div>
    </>
  );
};

export default LoginForm;
