import React from 'react';
import { Helmet } from 'react-helmet';
import LoginForm from '../components/LoginForm';
import { useAuth } from '../providers/AuthProvider';

const LoginScreen: React.FC = () => {
  // @ts-ignore
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    //history.push('/profile');
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | Uninow</title>
        <link rel="canonical" href="https://accounts.uninow.de/login" />
      </Helmet>
      <LoginForm />
    </>
  );
};

export default LoginScreen;
