import React from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookIcon, InstagramIcon, TwitterIcon, UniNowLogoSmall } from '@uninow/web-ui-lib/components/atoms';

const Footer: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white border-t border-gray-100 mt-12 rounded-t-md">
        <div className="mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="xl:col-span-1">
              <UniNowLogoSmall className="h-12 sm:h-16 fill-current text-gray-400" />
              <p className="mt-8 text-gray-500 text-base leading-6">{t('company.slogan')}</p>
              <div className="mt-8 flex">
                <a
                  href="https://www.facebook.com/UniNow/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Facebook</span>
                  <FacebookIcon className="h-6 w-6" />
                </a>
                <a
                  href="https://www.instagram.com/uninow_app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-6 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Instagram</span>
                  <InstagramIcon className="h-6 w-6" />
                </a>
                <a
                  href="https://twitter.com/uninowapp"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-6 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Twitter</span>
                  <TwitterIcon className="h-6 w-6" />
                </a>
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div></div>
                <div className="mt-12 md:mt-0"></div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                    {t('footer.company')}
                  </h4>
                  <ul className="mt-4">
                    <li className="mt-4">
                      <a
                        href="https://uninow.de/kontakt"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-base leading-6 text-gray-500 hover:text-gray-900"
                      >
                        {t('footer.contact')}
                      </a>
                    </li>
                    <li className="mt-4">
                      <a
                        href="https://uninow.de/news"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-base leading-6 text-gray-500 hover:text-gray-900"
                      >
                        {t('footer.news')}
                      </a>
                    </li>
                    <li className="mt-4">
                      <a
                        href="https://uninow.de/karriere"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-base leading-6 text-gray-500 hover:text-gray-900"
                      >
                        {t('footer.career')}
                      </a>
                    </li>
                    <li className="mt-4">
                      <a
                        href="https://uninow.de/faq"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-base leading-6 text-gray-500 hover:text-gray-900"
                      >
                        {t('footer.faq')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                    {t('footer.legal')}
                  </h4>
                  <ul className="mt-4">
                    <li>
                      <a target="_blank" rel="noreferrer noopener" href="https://legal.uninow.com/accounts/terms-of-use-uninow-account/de/latest" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                        {t('footer.terms')}
                      </a>
                    </li>
                    <li className="mt-4">
                      <a target="_blank" rel="noreferrer noopener" href="https://legal.uninow.com/accounts/privacy-policy-uninow-account/de/latest" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                        {t('footer.privacy')}
                      </a>
                    </li>
                    <li className="mt-4">
                      <a target="_blank" rel="noreferrer noopener" href="https://legal.uninow.com/web/imprint/de/latest" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                        {t('footer.imprint')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base leading-6 text-gray-400 xl:text-center">
              &copy; Copyright UniNow GmbH - All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
